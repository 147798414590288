.edit-image__fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.edit-image__header {
  margin-bottom: 16px;
}
.edit-image__row {
  margin-bottom: 12px;
}
.edit-image__form {
  margin-top: 32px;
}
.edit-image__legend {
  width: 100%;
}
.edit-image__image {
  width: 100%;
  max-width: 300px;
}
.edit-image__upload-wrapper > * + * {
  margin-top: 12px;
}
.edit-image__upload {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}
@media (min-width: 600px) {
  .edit-image__upload {
    flex-direction: row;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9wYWdlcy9lZGl0LWltYWdlIiwic291cmNlcyI6WyJlZGl0LWltYWdlLnNjc3MiLCIuLi8uLi9ub2RlX21vZHVsZXMvQHNhdHMtZ3JvdXAvdWktbGliL3Rva2Vucy9zcGFjaW5nLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRSxlQ05BOztBRFNGO0VBQ0UsZUNYQTs7QURjRjtFQUNFLFlDWkM7O0FEZUg7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7O0FBSUE7RUFDRSxZQzdCRjs7QURpQ0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQSxLQ3BDQTs7QURzQ0E7RUFORjtJQU9JIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQHNhdHMtZ3JvdXAvdWktbGliL3Rva2Vucy9zcGFjaW5nJztcblxuLmVkaXQtaW1hZ2Uge1xuICAmX19maWVsZHNldCB7XG4gICAgYm9yZGVyOiAwO1xuICAgIG1hcmdpbjogMDtcbiAgICBwYWRkaW5nOiAwO1xuICB9XG5cbiAgJl9faGVhZGVyIHtcbiAgICBtYXJnaW4tYm90dG9tOiBzcGFjaW5nLiRtO1xuICB9XG5cbiAgJl9fcm93IHtcbiAgICBtYXJnaW4tYm90dG9tOiBzcGFjaW5nLiRzO1xuICB9XG5cbiAgJl9fZm9ybSB7XG4gICAgbWFyZ2luLXRvcDogc3BhY2luZy4keGw7XG4gIH1cblxuICAmX19sZWdlbmQge1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG5cbiAgJl9faW1hZ2Uge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIG1heC13aWR0aDogMzAwcHg7XG4gIH1cblxuICAmX191cGxvYWQtd3JhcHBlciB7XG4gICAgPiAqICsgKiB7XG4gICAgICBtYXJnaW4tdG9wOiBzcGFjaW5nLiRzO1xuICAgIH1cbiAgfVxuXG4gICZfX3VwbG9hZCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LXdyYXA6IHdyYXA7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBnYXA6IHNwYWNpbmcuJG07XG5cbiAgICBAbWVkaWEgKG1pbi13aWR0aDogNjAwcHgpIHtcbiAgICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAgfVxuICB9XG59XG4iLCIvLyBodHRwczovL3d3dy5maWdtYS5jb20vZmlsZS9XektDd1JZMDl6bjRyelJWZlkwWXZkUnQvc2F0cy1kcy1zdHlsZXM/bm9kZS1pZD00MDglM0E4NVxuJHh4czogNHB4O1xuJHhzOiA4cHg7XG4kczogMTJweDtcbiRtOiAxNnB4O1xuJGw6IDI0cHg7XG4keGw6IDMycHg7XG4keHhsOiA2NHB4O1xuJHh4eGw6IDEyOHB4O1xuXG4kaGVybzogMjU2cHg7XG4kbWluaW11bS12aWV3cG9ydC13aWR0aDogMzIwcHg7XG4iXX0= */