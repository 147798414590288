@use '@sats-group/ui-lib/tokens/spacing';

.edit-image {
  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  &__header {
    margin-bottom: spacing.$m;
  }

  &__row {
    margin-bottom: spacing.$s;
  }

  &__form {
    margin-top: spacing.$xl;
  }

  &__legend {
    width: 100%;
  }

  &__image {
    width: 100%;
    max-width: 300px;
  }

  &__upload-wrapper {
    > * + * {
      margin-top: spacing.$s;
    }
  }

  &__upload {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacing.$m;

    @media (min-width: 600px) {
      flex-direction: row;
    }
  }
}
